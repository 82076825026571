<template>
  <v-container class="personal-discounts">
    <div class="text-body-0 primary--text font-weight-semibold my-4">
      {{
        $t("coupons.galery.active", {
          number: activeCoupons
        })
      }}
    </div>
    <v-row v-if="coupons && coupons.length > 0"
      ><v-col
        cols="12"
        sm="6"
        v-for="(coupon, index) in coupons"
        :key="'coupon-' + index"
        >
        <ProductCardBlackFriday v-if="coupon.metaData.voucher_info.key"
          :product="coupon"
          :type="
            coupon.metaData.voucher_info.selected ? 'selected' : 'available'
          "
          @openDetails="openDetails"
          @reload="loadCoupons"/>
        <ProductCard
          v-else
          :product="coupon"
          :type="
            coupon.metaData.voucher_info.selected ? 'selected' : 'available'
          "
          @openDetails="openDetails"
          @reload="loadCoupons"/>

          </v-col
    ></v-row>
    <div
      v-else
      class="text-center font-weight-semibold secondary--text text-body-0 py-6"
    >
      {{
        type == "available"
          ? $t("coupons.galery.noAvailable")
          : $t("coupons.galery.noActive")
      }}
    </div>
  </v-container>
</template>
<style lang="scss">
.personal-discounts {
  padding: 10px 10px;
}
</style>
<script>
import CouponsService from "@/service/couponsService";
import GaleryCouponDetail from "@/components/coupon/GaleryCouponDetail.vue";
import ProductCardBlackFriday from "@/components/product/ProductCardBlackFriday.vue";
import ProductCard from "@/components/product/ProductCard.vue";

export default {
  components: { ProductCardBlackFriday,ProductCard },
  name: "PersonalDiscounts",
  props: {
    type: { type: String, default: "available" }
  },
  data() {
    return {
      activeCoupons: 0,
      selected: [],
      available: [],
      coupons: []
    };
  },
  computed: {},
  methods: {
    async loadCoupons() {
      this.selected = await this.getSelectedCoupons();
      this.available = await this.getCoupons();
      this.coupons = [];
      if (this.selected.giftCertificates) {
        this.coupons.push(...this.selected.giftCertificates);
      }
      if (this.available.giftCertificates) {
        this.coupons.push(...this.available.giftCertificates);
      }
      this.activeCoupons = this.selected.giftCertificates
        ? this.selected.giftCertificates.length
        : 0;
    },
    async getSelectedCoupons() {
      return CouponsService.getCouponGallery(true);
    },
    async getCoupons() {
      return CouponsService.getCouponGallery(false);
    },
    async openDetails(item) {
      let res = await this.$dialog.show(GaleryCouponDetail, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        coupon: item,
        type: item.metaData.voucher_info.selected ? "selected" : "available",
        width: 500
      });
      if (res) {
        this.loadCoupons();
      }
    }
  },
  mounted() {
    this.loadCoupons();
  }
};
</script>
